/**
 * Important Notice:
 *
 * If Scripts like select2, owlCarousel or fancybox are needed,
 * the have to be moved from libs_notUsed to libs. Actually only
 * the flexnav files are in the folder libs.
 */

jQuery(function() {

    var $ = jQuery;
    $.ajaxSetup({
        cache: true
    });

    $(".flexnav").flexNav({});

    if ($('.slide').length) {
        var mySwiper = $('.swiper').swiper({
            slideClass: 'slide',
            loop: true,
            grabCursor: false,
            simulateTouch: false,
            autoplay: $('.slide').length > 1 ? 2000 : false,
            speed: 2000,
            pagination: $('.slide').length > 1 ? '.pagination' : false,
            paginationClickable: true
        });
    }

});
